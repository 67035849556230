import React from "react"
import Layout from "../../../components/sermonLayoutSurprise"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://player.vimeo.com/video/393467032">
    <SEO title="Things Are Getting Serious - Love Song" />
  </Layout>
)

export default SermonPost
